
export default {
  data(){
    return{
      message: '',
    }    
  },
  async fetch() {
    try {
      const p = await this.$fire.firestore.collection('systembar').doc('systembarmessage').get()

      if (!p.exists) throw new Error('No system bar message found')
      this.message = p.data().value

    } catch (error) {
      console.error(error)
    }
  },
  fetchOnServer: process.env.NODE_ENV !== 'development',
}
