import { render, staticRenderFns } from "./default.vue?vue&type=template&id=de23dff4"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DefaultSystemBar: require('/opt/build/repo/components/Default/SystemBar.vue').default,DefaultNavBar: require('/opt/build/repo/components/Default/NavBar.vue').default})
