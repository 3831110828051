
import { mdiCart, mdiAccount } from '@mdi/js'
import { mapGetters } from 'vuex'
export default {
  props: {
    routes: Array,
  },
  data: () => ({
    mdiCart,
    mdiAccount,
    drawer: null,
    color: 'transparent',
  }),
  methods: {
    toggleDrawer() {
      this.$emit('toggleDrawer')
    },
  },
  computed: {
    ...mapGetters('cart', ['cartLength']),
  },
}
