import { status } from '@/constants/status'

export const state = () => ({
  /*     product: {}, */
})

export const getters = {
  /*     selectedProduct: (state) => {
      return state.product
    }, */
}

export const mutations = {
  /*     SET_PRODUCT(state, product) {
      state.product = product
    }, */
}

export const actions = {
  async addOrder({ commit, dispatch, state }, order) {
    try {
      const { serverTimestamp,increment } = this.$fireModule.firestore.FieldValue;
      const { uid } = this.getters['auth/authUser'];
      const cart = this.getters['cart/productsInCart'];
      const globalOrderRef = this.$fire.firestore.collection('orders').doc();
      const s = status.find((v) => v.value === 'Awaiting Payment Confirmation');
      
      const batch = this.$fire.firestore.batch();

      const orderData = {
        ...order,
        customerUid: uid ? uid : 'N/A',
        cart: cart,
        status: s,
        dateAdded: serverTimestamp(),
      }
      
      batch.set(globalOrderRef, orderData);

      //Deduct stock from applicable products
      for (const product of cart) {
        // Access nested property in object
        // https://youtu.be/UgEaJBz3bjY?t=99
        
        //const { selectedOption: {firestoreOptionId, isStockTrackingEnabled}, selectedOption,orderedQuantity, product: {id}} = product;
        const { selectedOption,orderedQuantity, product: {id}} = product;
        if(!selectedOption) break;
        const { firestoreOptionId, isStockTrackingEnabled }=selectedOption

       

        const productDir = `products/${id}/options/${firestoreOptionId}`;
        const productRef = this.$fire.firestore.doc(productDir);

        // convert the amount the customer ordered into a negative amount since there is only an option in increment in Firestore
        //https://stackoverflow.com/questions/55621632/firestore-how-to-decrement-integer-value-atomically-in-database
        const decrementAmount = -1 * orderedQuantity;
        
        // let firestore increment the field with a negative value
        const updateObject = {stock: increment(decrementAmount)}

        batch.update(productRef, updateObject , {merge: true})
      }

      

      

      if (uid){
        const userOrdersDir = `users/${uid}/orders`
        const customerOrderRef = this.$fire.firestore.collection(userOrdersDir).doc();
        
        const custOrderData = {
          ...order,
          cart: cart,
          status: s,
          dateAdded: serverTimestamp(),
          globalOrderRef: globalOrderRef.id,
        };

        batch.set(customerOrderRef, custOrderData);
      } 
      
      const globalOrderWrite = await batch.commit();
      return Promise.resolve(globalOrderWrite)




    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getUserOrders({ commit, dispatch, state }) {
    try {
      const { uid } = this.getters['auth/authUser']
      if (!uid) throw new Error('UID not valid')

      const orderCol = await this.$fire.firestore
        .collection(`users/${uid}/orders`)
        .get()
      if (orderCol.empty) return []

      const orderDocs = orderCol.docs.map((doc) => {
        return { ...doc.data(), id: doc.id, ref: doc.ref }
      })

      return Promise.resolve(orderDocs)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async getAuthUserOrder({ commit, dispatch, state }, id) {
    try {
      const { uid } = this.getters['auth/authUser']
      if (!uid) throw new Error('UID not valid')

      const orderDoc = await this.$fire.firestore
        .doc(`users/${uid}/orders/${id}`)
        .get()
      if (!orderDoc.exists) throw new Error('No matching order ID')

      return Promise.resolve(orderDoc)
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async getAdminOrder({ commit, dispatch, state }, id) {
    try {
      const { uid,isAdmin } = this.getters['auth/authUser']
      if(!isAdmin) throw new Error('Insufficent privileges')
      if (!uid) throw new Error('UID not valid')

      const orderDoc = await this.$fire.firestore
        .doc(`orders/${id}`)
        .get()
      if (!orderDoc.exists) throw new Error('No matching order ID')

      return Promise.resolve(orderDoc)
    } catch (error) {
      return Promise.reject(error)
    }
  },
}
